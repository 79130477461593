import React from 'react'
import Home from '../components/Home/Home'

function HomeMain({setAdminDisplay}) {
  
  return (
    <div><Home setAdminDisplay={setAdminDisplay}/></div>
  )
}

export default HomeMain