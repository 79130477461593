import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getUser } from "../../../app/actions/user/getUser";
import logo from "../../../assets/PNG-02.png";

const SignIn = ({ visible, setShowLogin }) => {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const ref = useRef();
  const { t } = useTranslation("googleModal");

  let userObject = "";

  useEffect(() => {
    if (user.name) {
      dispatch(getUser(user));
    }
  }, [user]);

  useEffect(() => {
    const checkClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowLogin(false);
      }
    };
    document.addEventListener("mousedown", checkClickOutside);

    return () => {
      document.removeEventListener("mousedown", checkClickOutside);
    };
  }, [setShowLogin, ref]);

  if (!visible) {
    return null;
  } else {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex flex-col justify-center items-center z-50 animate-fade animate-once animate-duration-1000 animate-ease-in">
        <div
          className="w-[90%] lg:w-1/2 h-1/2 opacity-100 rounded-2xl flex flex-col justify-center bg-coal gap-4 animate-fade animate-once animate-duration-[2000ms] animate-delay-300 animate-ease-in border-2"
          ref={ref}
        >
          {/* "sign in with google" */}

          <span className="bg-white flex items-center text-gray-700  justify-center gap-x-3 text-sm sm:text-base rounded-lg hover:bg-gray-100 duration-300 transition-colors border px-8 py-2.5 uppercase mx-auto -mt-20 lg:-mt-12 sm:-mt-12 xl:-mt-16 z-50">
            <svg
              className="w-5 h-5 sm:h-6 sm:w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3033_94454)">
                <path
                  d="M23.766 12.2764C23.766 11.4607 23.6999 10.6406 23.5588 9.83807H12.24V14.4591H18.7217C18.4528 15.9494 17.5885 17.2678 16.323 18.1056V21.1039H20.19C22.4608 19.0139 23.766 15.9274 23.766 12.2764Z"
                  fill="#4285F4"
                />
                <path
                  d="M12.2401 24.0008C15.4766 24.0008 18.2059 22.9382 20.1945 21.1039L16.3276 18.1055C15.2517 18.8375 13.8627 19.252 12.2445 19.252C9.11388 19.252 6.45946 17.1399 5.50705 14.3003H1.5166V17.3912C3.55371 21.4434 7.7029 24.0008 12.2401 24.0008Z"
                  fill="#34A853"
                />
                <path
                  d="M5.50253 14.3003C4.99987 12.8099 4.99987 11.1961 5.50253 9.70575V6.61481H1.51649C-0.18551 10.0056 -0.18551 14.0004 1.51649 17.3912L5.50253 14.3003Z"
                  fill="#FBBC04"
                />
                <path
                  d="M12.2401 4.74966C13.9509 4.7232 15.6044 5.36697 16.8434 6.54867L20.2695 3.12262C18.1001 1.0855 15.2208 -0.034466 12.2401 0.000808666C7.7029 0.000808666 3.55371 2.55822 1.5166 6.61481L5.50264 9.70575C6.45064 6.86173 9.10947 4.74966 12.2401 4.74966Z"
                  fill="#EA4335"
                />
              </g>
              <defs>
                <clipPath id="clip0_3033_94454">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <span>{t("signin")}</span>
          </span>

          {/* logo & text container */}
          <div className="mx-auto p-8 rounded-xl shadow-lg shadow-black">
            {/* logo */}
            <div className="flex justify-center">
              <img src={logo} alt="Logo" border="0" className="w-28 " />
            </div>

            {/* text */}
            <div>
              <h1 className="text-white text-xl text-center h-full">
                {t("mainMessage")}
              </h1>
            </div>
          </div>

          {/* sing in button */}
          <div className="flex flex-col justify-center">
            <div className="flex justify-center">
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  userObject = jwt_decode(credentialResponse.credential);
                  setUser(userObject);
                  setShowLogin(false);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SignIn;
